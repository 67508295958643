import React, { useEffect, useState, useContext } from 'react';
import { LanguageContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import ResultsView from 'ToolboxComponents/webapp/pages/results-view/results-view';
import Text from 'ToolboxComponents/commons/texts/text/text';


const ClusterView = ({ cluster, products, hasNoDescription, addFavorite, deleteFavorite }) => {
  return (
    <ResultsView
      products={products}
      addFavorite={event => addFavorite(event)}
      deleteFavorite={event => deleteFavorite(event)}
      hasFilters={false}
      hasNoDescription={hasNoDescription}
      specialHeader= {{
        title: cluster.title,
        teaser: cluster.teaser,
        highlight: <Text path={`page.majors.enjoy`} />
      }}
    />
  );
};

ClusterView.propTypes = {
  products: PropTypes.array.isRequired,
  addFavorite: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
};

export default ClusterView;
